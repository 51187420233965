import { captureException } from "@sentry/vue";
import {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
  isAxiosError as nativeAxiosError,
} from "axios";
import axios from "axios";
import mem from "mem";

import type {
  IErrorResponse,
  IOAuthErrorResponse,
} from "@/api/types/general-types";
import { useNotifications } from "@/modules/snackbar/composables/use-notifications";
import { useAuthStore } from "@/store/auth-store";
import { getPersistedLanguage } from "@/utils/user-settings-utils";

const status = {
  unauthorized: 401,
};

export function isAxiosError(e: unknown): e is AxiosError {
  return nativeAxiosError(e);
}

export function isErrorResponse(e: unknown): e is AxiosError<IErrorResponse> {
  return (
    isAxiosError(e) &&
    (e.response?.data as IErrorResponse)?.error?.details != null
  );
}

export function isOAuthError(e: unknown): e is AxiosError<IOAuthErrorResponse> {
  return (
    isErrorResponse(e) &&
    (e.response?.data as IOAuthErrorResponse)?.error?.code ===
      "general.unauthorized"
  );
}

export function requestInterceptor<T extends AxiosRequestConfig>(config: T) {
  if (config.baseURL === import.meta.env.VITE_APP_API_URL) {
    const authStore = useAuthStore();
    const token = authStore.getToken;

    if (token) {
      config.headers = {
        ...config.headers,
        "Content-Language": getPersistedLanguage(),
        authorization: `Bearer ${token}`,
      };
    }
  }

  return config;
}

export async function responseSuccessInterceptor(response: AxiosResponse) {
  return response.data;
}

export function refreshToken(): Promise<unknown> {
  const authStore = useAuthStore();
  if (authStore.hasRefreshToken) {
    try {
      return authStore
        .refreshToken()
        .then(() => {
          return true;
        })
        .catch(() => {
          return authStore.logout().then(() => {
            return false;
          });
        });
    } catch (e) {
      return authStore.logout().then(() => {
        return false;
      });
    }
  } else {
    return authStore.logout().then(() => {
      return false;
    });
  }
}

export const defaultHeaders = {
  "Content-Type": "application/json",
  "X-Requested-With": "XMLHttpRequest",
};

export const sharedConfig = {
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: defaultHeaders,
};

const maxAge = 5000;
export const memoizedRefreshToken = mem(refreshToken, {
  maxAge,
});

async function errorInterceptor(error: AxiosError, showSnackbar = false) {
  const config = error.config as InternalAxiosRequestConfig & {
    sent?: boolean;
  };

  const url: string = error.request.responseURL || "";

  if (url.endsWith("/oauth/token") || url.endsWith("/api/check")) {
    // skipping error interceptor for login/refresh
    throw error;
  }

  const authStore = useAuthStore();

  if (
    isAxiosError(error) &&
    error.response?.status === status.unauthorized &&
    !config?.sent &&
    !authStore.loggedInAsAdmin
  ) {
    config.sent = true;
    // only retry if refresh token returns true - in this case the refresh token worked
    const doRetry = await memoizedRefreshToken();
    if (doRetry) {
      return httpClient(requestInterceptor(config));
    }
    throw error;
  } else {
    if (isErrorResponse(error)) {
      if (
        isOAuthError(error) &&
        (error.response?.data?.error?.details?.error_message ===
          "The refresh token is invalid." ||
          error.response?.status === status.unauthorized)
      ) {
        return await authStore.logout();
      } else {
        captureException(error);
      }
    }

    if (showSnackbar) {
      const { showErrorResponse } = useNotifications();
      showErrorResponse(error);
    }

    throw error;
  }
}

export const httpClient = axios.create(sharedConfig);
httpClient.interceptors.request.use(requestInterceptor);
httpClient.interceptors.response.use(responseSuccessInterceptor, (error) =>
  errorInterceptor(error, true)
);

export default httpClient;

/**
 * Does not post any snackbars
 */
export const silentHttpClient = axios.create(sharedConfig);
silentHttpClient.interceptors.request.use(requestInterceptor);
silentHttpClient.interceptors.response.use(
  responseSuccessInterceptor,
  (error) => errorInterceptor(error, false)
);
